import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _251ccc08 = () => interopDefault(import('../client/pages/cart/index.vue' /* webpackChunkName: "" */))
const _196fea14 = () => interopDefault(import('../client/pages/products/no-minimum/_.vue' /* webpackChunkName: "" */))
const _6e5e6830 = () => interopDefault(import('../client/pages/products/closeout/_.vue' /* webpackChunkName: "" */))
const _bc2dccb2 = () => interopDefault(import('../client/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _63cb1408 = () => interopDefault(import('../client/pages/cartitemsadded/index.vue' /* webpackChunkName: "pages/cartitemsadded/index" */))
const _21978627 = () => interopDefault(import('../client/pages/changepassword/index.vue' /* webpackChunkName: "pages/changepassword/index" */))
const _39c6d018 = () => interopDefault(import('../client/pages/customer-artwork-proof/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/index" */))
const _097f4bef = () => interopDefault(import('../client/pages/designs/index.vue' /* webpackChunkName: "pages/designs/index" */))
const _54550938 = () => interopDefault(import('../client/pages/facets/index.vue' /* webpackChunkName: "pages/facets/index" */))
const _3144bcde = () => interopDefault(import('../client/pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _0497024c = () => interopDefault(import('../client/pages/forgotpassword/index.vue' /* webpackChunkName: "pages/forgotpassword/index" */))
const _7ae66196 = () => interopDefault(import('../client/pages/health.vue' /* webpackChunkName: "pages/health" */))
const _49fd8bfc = () => interopDefault(import('../client/pages/not-found/index.vue' /* webpackChunkName: "pages/not-found/index" */))
const _49997884 = () => interopDefault(import('../client/pages/proof/index.vue' /* webpackChunkName: "pages/proof/index" */))
const _0dec553e = () => interopDefault(import('../client/pages/proofitemsadded/index.vue' /* webpackChunkName: "pages/proofitemsadded/index" */))
const _1a24fe76 = () => interopDefault(import('../client/pages/quoteitemsadded/index.vue' /* webpackChunkName: "pages/quoteitemsadded/index" */))
const _91722638 = () => interopDefault(import('../client/pages/resetpassword/index.vue' /* webpackChunkName: "pages/resetpassword/index" */))
const _ffefa974 = () => interopDefault(import('../client/pages/sampleitemsadded/index.vue' /* webpackChunkName: "pages/sampleitemsadded/index" */))
const _449167be = () => interopDefault(import('../client/pages/signin/index.vue' /* webpackChunkName: "pages/signin/index" */))
const _88c1b22a = () => interopDefault(import('../client/pages/signout/index.vue' /* webpackChunkName: "pages/signout/index" */))
const _01347361 = () => interopDefault(import('../client/pages/suppliers/index.vue' /* webpackChunkName: "pages/suppliers/index" */))
const _90496e3e = () => interopDefault(import('../client/pages/testy/index.vue' /* webpackChunkName: "pages/testy/index" */))
const _2cb5034f = () => interopDefault(import('../client/pages/version.vue' /* webpackChunkName: "pages/version" */))
const _b5b11634 = () => interopDefault(import('../client/pages/version-app/index.vue' /* webpackChunkName: "pages/version-app/index" */))
const _47138b7e = () => interopDefault(import('../client/pages/cat/GeneralProductView.vue' /* webpackChunkName: "pages/cat/GeneralProductView" */))
const _17da0c88 = () => interopDefault(import('../client/pages/orders/products/index.vue' /* webpackChunkName: "pages/orders/products/index" */))
const _385dbc90 = () => interopDefault(import('../client/pages/products/utils.js' /* webpackChunkName: "pages/products/utils" */))
const _f44761ea = () => interopDefault(import('../client/pages/sso/facebook/delete.vue' /* webpackChunkName: "pages/sso/facebook/delete" */))
const _536baa5e = () => interopDefault(import('../client/pages/review/order/_orderId/product/_productId.vue' /* webpackChunkName: "pages/review/order/_orderId/product/_productId" */))
const _49f7d03a = () => interopDefault(import('../client/pages/products/search/_.vue' /* webpackChunkName: "pages/products/search/_" */))
const _55522cdc = () => interopDefault(import('../client/pages/blog/cat/_.vue' /* webpackChunkName: "pages/blog/cat/_" */))
const _5cea7790 = () => interopDefault(import('../client/pages/approval/_orderId/index.vue' /* webpackChunkName: "pages/approval/_orderId/index" */))
const _2be88343 = () => interopDefault(import('../client/pages/artproof/_productSlug/index.vue' /* webpackChunkName: "pages/artproof/_productSlug/index" */))
const _1155c670 = () => interopDefault(import('../client/pages/authors/_authorName/index.vue' /* webpackChunkName: "pages/authors/_authorName/index" */))
const _a3945a68 = () => interopDefault(import('../client/pages/components/_componentId/index.vue' /* webpackChunkName: "pages/components/_componentId/index" */))
const _5d8ab4bb = () => interopDefault(import('../client/pages/configureproduct/_productSlug/index.vue' /* webpackChunkName: "pages/configureproduct/_productSlug/index" */))
const _5bebc1fa = () => interopDefault(import('../client/pages/customer-artwork-proof/_id/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/_id/index" */))
const _5e9c9c4e = () => interopDefault(import('../client/pages/designer/_productSlug/index.vue' /* webpackChunkName: "pages/designer/_productSlug/index" */))
const _e9c3502c = () => interopDefault(import('../client/pages/designs/_slug/index.vue' /* webpackChunkName: "pages/designs/_slug/index" */))
const _9ccd8b1a = () => interopDefault(import('../client/pages/facets/_slug/index.vue' /* webpackChunkName: "pages/facets/_slug/index" */))
const _0f3ea023 = () => interopDefault(import('../client/pages/largequote/_productSlug/index.vue' /* webpackChunkName: "pages/largequote/_productSlug/index" */))
const _2df414a8 = () => interopDefault(import('../client/pages/orders/_id/index.vue' /* webpackChunkName: "pages/orders/_id/index" */))
const _fd7a7b34 = () => interopDefault(import('../client/pages/payments/_orderId/index.vue' /* webpackChunkName: "pages/payments/_orderId/index" */))
const _08d23c4e = () => interopDefault(import('../client/pages/presentation/_orderId/index.vue' /* webpackChunkName: "pages/presentation/_orderId/index" */))
const _6379233e = () => interopDefault(import('../client/pages/presentations/_id/index.vue' /* webpackChunkName: "pages/presentations/_id/index" */))
const _b924e95a = () => interopDefault(import('../client/pages/printinvoice/_orderId/index.vue' /* webpackChunkName: "pages/printinvoice/_orderId/index" */))
const _11900dfb = () => interopDefault(import('../client/pages/products/_slug/index.vue' /* webpackChunkName: "pages/products/_slug/index" */))
const _3b284fa9 = () => interopDefault(import('../client/pages/proof/_id/index.vue' /* webpackChunkName: "pages/proof/_id/index" */))
const _6137ebd5 = () => interopDefault(import('../client/pages/quickquote/_productSlug/index.vue' /* webpackChunkName: "pages/quickquote/_productSlug/index" */))
const _00844140 = () => interopDefault(import('../client/pages/reorder/_id/index.vue' /* webpackChunkName: "pages/reorder/_id/index" */))
const _1e0b77fa = () => interopDefault(import('../client/pages/sample/_productSlug/index.vue' /* webpackChunkName: "pages/sample/_productSlug/index" */))
const _7f59a6e2 = () => interopDefault(import('../client/pages/sso/_service.vue' /* webpackChunkName: "pages/sso/_service" */))
const _a51a7200 = () => interopDefault(import('../client/pages/cat/_slug/brand.vue' /* webpackChunkName: "pages/cat/_slug/brand" */))
const _57340c75 = () => interopDefault(import('../client/pages/orders/_id/track-shipment/index.vue' /* webpackChunkName: "pages/orders/_id/track-shipment/index" */))
const _2ebf6c84 = () => interopDefault(import('../client/pages/products/_slug/reviews.vue' /* webpackChunkName: "pages/products/_slug/reviews" */))
const _021635aa = () => interopDefault(import('../client/pages/facets/_slug/_facet/index.vue' /* webpackChunkName: "pages/facets/_slug/_facet/index" */))
const _21581cd5 = () => interopDefault(import('../client/pages/supplier/_psCode/_.vue' /* webpackChunkName: "pages/supplier/_psCode/_" */))
const _a444af50 = () => interopDefault(import('../client/pages/cat/_slug/_.vue' /* webpackChunkName: "pages/cat/_slug/_" */))
const _a8f442d4 = () => interopDefault(import('../client/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/box/cart",
    component: _251ccc08,
    name: "box/cart"
  }, {
    path: "/quote/cart",
    component: _251ccc08,
    name: "quote/cart"
  }, {
    path: "/sample/cart",
    component: _251ccc08,
    name: "sample/cart"
  }, {
    path: "/proof/cart",
    component: _251ccc08,
    name: "proof/cart"
  }, {
    path: "/boxcart",
    component: _251ccc08,
    name: "boxcart"
  }, {
    path: "/quotecart",
    component: _251ccc08,
    name: "quotecart"
  }, {
    path: "/samplecart",
    component: _251ccc08,
    name: "samplecart"
  }, {
    path: "/proofcart",
    component: _251ccc08,
    name: "proofcart"
  }, {
    path: "/products/no-minimum",
    component: _196fea14,
    name: "products-no-minimum"
  }, {
    path: "/products/closeout",
    component: _6e5e6830,
    name: "products-closeout"
  }, {
    path: "/account",
    component: _bc2dccb2,
    name: "account"
  }, {
    path: "/cart",
    component: _251ccc08,
    name: "cart"
  }, {
    path: "/cartitemsadded",
    component: _63cb1408,
    name: "cartitemsadded"
  }, {
    path: "/changepassword",
    component: _21978627,
    name: "changepassword"
  }, {
    path: "/customer-artwork-proof",
    component: _39c6d018,
    name: "customer-artwork-proof"
  }, {
    path: "/designs",
    component: _097f4bef,
    name: "designs"
  }, {
    path: "/facets",
    component: _54550938,
    name: "facets"
  }, {
    path: "/favorites",
    component: _3144bcde,
    name: "favorites"
  }, {
    path: "/forgotpassword",
    component: _0497024c,
    name: "forgotpassword"
  }, {
    path: "/health",
    component: _7ae66196,
    name: "health"
  }, {
    path: "/not-found",
    component: _49fd8bfc,
    name: "not-found"
  }, {
    path: "/proof",
    component: _49997884,
    name: "proof"
  }, {
    path: "/proofitemsadded",
    component: _0dec553e,
    name: "proofitemsadded"
  }, {
    path: "/quoteitemsadded",
    component: _1a24fe76,
    name: "quoteitemsadded"
  }, {
    path: "/resetpassword",
    component: _91722638,
    name: "resetpassword"
  }, {
    path: "/sampleitemsadded",
    component: _ffefa974,
    name: "sampleitemsadded"
  }, {
    path: "/signin",
    component: _449167be,
    name: "signin"
  }, {
    path: "/signout",
    component: _88c1b22a,
    name: "signout"
  }, {
    path: "/suppliers",
    component: _01347361,
    name: "suppliers"
  }, {
    path: "/testy",
    component: _90496e3e,
    name: "testy"
  }, {
    path: "/version",
    component: _2cb5034f,
    name: "version"
  }, {
    path: "/version-app",
    component: _b5b11634,
    name: "version-app"
  }, {
    path: "/cat/GeneralProductView",
    component: _47138b7e,
    name: "cat-GeneralProductView"
  }, {
    path: "/orders/products",
    component: _17da0c88,
    name: "orders-products"
  }, {
    path: "/products/utils",
    component: _385dbc90,
    name: "products-utils"
  }, {
    path: "/sso/facebook/delete",
    component: _f44761ea,
    name: "sso-facebook-delete"
  }, {
    path: "/review/order/:orderId?/product/:productId?",
    component: _536baa5e,
    name: "review-order-orderId-product-productId"
  }, {
    path: "/products/search/*",
    component: _49f7d03a,
    name: "products-search-all"
  }, {
    path: "/products/no-minimum/*",
    component: _196fea14,
    name: "products-no-minimum-all"
  }, {
    path: "/products/closeout/*",
    component: _6e5e6830,
    name: "products-closeout-all"
  }, {
    path: "/blog/cat/*",
    component: _55522cdc,
    name: "blog-cat-all"
  }, {
    path: "/approval/:orderId",
    component: _5cea7790,
    name: "approval-orderId"
  }, {
    path: "/artproof/:productSlug",
    component: _2be88343,
    name: "artproof-productSlug"
  }, {
    path: "/authors/:authorName",
    component: _1155c670,
    name: "authors-authorName"
  }, {
    path: "/components/:componentId",
    component: _a3945a68,
    name: "components-componentId"
  }, {
    path: "/configureproduct/:productSlug",
    component: _5d8ab4bb,
    name: "configureproduct-productSlug"
  }, {
    path: "/customer-artwork-proof/:id",
    component: _5bebc1fa,
    name: "customer-artwork-proof-id"
  }, {
    path: "/designer/:productSlug",
    component: _5e9c9c4e,
    name: "designer-productSlug"
  }, {
    path: "/designs/:slug",
    component: _e9c3502c,
    name: "designs-slug"
  }, {
    path: "/facets/:slug",
    component: _9ccd8b1a,
    name: "facets-slug"
  }, {
    path: "/largequote/:productSlug",
    component: _0f3ea023,
    name: "largequote-productSlug"
  }, {
    path: "/orders/:id",
    component: _2df414a8,
    name: "orders-id"
  }, {
    path: "/payments/:orderId",
    component: _fd7a7b34,
    name: "payments-orderId"
  }, {
    path: "/presentation/:orderId",
    component: _08d23c4e,
    name: "presentation-orderId"
  }, {
    path: "/presentations/:id",
    component: _6379233e,
    name: "presentations-id"
  }, {
    path: "/printinvoice/:orderId",
    component: _b924e95a,
    name: "printinvoice-orderId"
  }, {
    path: "/products/:slug",
    component: _11900dfb,
    name: "products-slug"
  }, {
    path: "/proof/:id",
    component: _3b284fa9,
    name: "proof-id"
  }, {
    path: "/quickquote/:productSlug",
    component: _6137ebd5,
    name: "quickquote-productSlug"
  }, {
    path: "/reorder/:id",
    component: _00844140,
    name: "reorder-id"
  }, {
    path: "/sample/:productSlug",
    component: _1e0b77fa,
    name: "sample-productSlug"
  }, {
    path: "/sso/:service?",
    component: _7f59a6e2,
    name: "sso-service"
  }, {
    path: "/cat/:slug?/brand",
    component: _a51a7200,
    name: "cat-slug-brand"
  }, {
    path: "/orders/:id?/track-shipment",
    component: _57340c75,
    name: "orders-id-track-shipment"
  }, {
    path: "/products/:slug?/reviews",
    component: _2ebf6c84,
    name: "products-slug-reviews"
  }, {
    path: "/facets/:slug/:facet",
    component: _021635aa,
    name: "facets-slug-facet"
  }, {
    path: "/supplier/:psCode?/*",
    component: _21581cd5,
    name: "supplier-psCode-all"
  }, {
    path: "/cat/:slug?/*",
    component: _a444af50,
    name: "cat-slug-all"
  }, {
    path: "/*",
    component: _a8f442d4,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
